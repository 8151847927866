export default {
  or: "or",
  name: "Name",
  status: "Status",
  back: "Back",
  id: "Id",
  sku: "Sku",
  oemSKU: "Oem Sku",
  description: "Description",
  price: "Price",
  fullPrice: "List Price",
  orderPrice: "Order Price",
  stock: "Stock",
  code: "Code",
  invoiced: "Invoiced",
  change: "Change",
  address: "Address",
  addressMissing: "Missing address",
  creationDate: "Creation date",
  timeZone: "Time Zone",
  languageCode: "Language Code",
  roles: "Roles",
  permissions: "Permissions",
  years: "years",
  months: "months",
  days: "days",
  sales: "Sales",
  dashboard: "Dashboard",
  salesNetwork: "Sales Network",
  marketing: "Marketing",
  brandAssets: "Brand Assets",
  support: "Support",
  knowledgebase: "Knowledge Base",
  helpdesk: "Help Desk",
  designSystem: "Design System",
  devMenu: "Development",
  actions: "Actions",
  confirm: "Confirm",
  dismiss: "Dismiss",
  create: "Create",
  edit: "Edit",
  save: "Save",
  password: "Password",
  repeatPassword: "Repeat password",
  passwordCriteria:
    "At least 8 digits and contain an uppercase, lowercase, and special character and a number",
  search: "Search",
  reset: "Reset",
  group: "Group",

  profile: "Profile",
  company: "Company",
  accounts: "Accounts",
  userId: "Users",
  network: "Network",
  backToNetwork: "Back to network",
  certifications: "Certifications",
  sellOut: "Sell Out",
  sellIn: "Sell In",
  pricelList: "Price List",
  licenseKeys: "License Keys",
  email: "Email",
  noChildAccounts: "No account in this network",
  profileEdit: "Edit profile",
  backToProfile: "Back to profile",
  userUpdated: "Profile updated",
  userUpdateError: "Error updating profile",
  dangerZone: "Danger Zone",
  deleteProfile: "Delete Profile",
  deleteMe: "Delete me",
  profileRemove: "Delete your profile",
  profileRemoveDescription:
    "Are you sure you want to delete your profile? You won't be able to login anymore.",

  companyEdit: "Edit Company",
  backToCompany: "Back to Company",
  companyAddress: "Company Address",

  priceLists: "Price Lists",
  priceList: "Price List",
  priceListName: "Price List Name",
  priceListNew: "New Price List",
  priceListBack: "Back to Price Lists",
  priceListsProduct: "Price list item",
  priceListsProductNew: "New price list item",
  priceListsCreateError: "Error creating price list",
  priceListsFetchError: "Error fetching price lists",
  priceListsDeleteError: "Error deleting price list",
  priceListsDeleteErrorInuse: "Error deleting: Price list in use",
  priceListsUpdateError: "Error updating price list",
  priceListsUpdateSuccess: "Price List updated",
  priceListsRemove: "Delete price list",
  priceListsRemoveDescription: "Are you sure you want to delete price list?",
  priceListsRemoveProduct: "Remove Product",
  priceListsRemoveProductDescription:
    "Do you want to remove product from pricelist?",
  priceListAssociated: "Linked price list",
  priceListNotAssociated: "No linked price list",
  priceListAssociate: "Link price list",
  pricelistAssociatePropagation: "Make available to children accounts",
  fixed: "Fixed",
  percentage: "Percentage",

  newAccountTitle: "New Partner Account",
  accountName: "Account name",
  accountAdmin: "Administrator",
  firstName: "First Name",
  lastName: "Last Name",
  createAsChild: "Create as child account",
  accountRemove: "Delete account",
  accountRemoveDescription: "Are you sure to delete account?",
  searchAccount: "search company",
  parentAccount: "Parent account",
  accountUpdateSuccess: "Account updated",
  notificationEnabled: "Receive email notification",
  notificationReceiverEmail: "Company notification email",

  legalName: "Legal name",
  street: "Street",
  city: "City",
  locality: "Locality",
  region: "State",
  postalCode: "ZIP",
  country: "Country",
  countryCode: "Country code",
  vatId: "Tax ID /  Company Number",
  accountGroup: "Account type",

  licenses: "Licenses",
  license: "License",
  serialKey: "Serial Key",
  seats: "Seats",
  hasBeenActivated: "Active",
  licenseFetchError: "Error fetching license",
  licensesFetchError: "Error fetching licenses",
  clearSeatError: "Error clearing seat",
  clearSeatConfirm: "Are you sure you want to suspend seats?",
  clearSeatTitle: "Seats Suspend",
  licenseReports: "Licenses Reports",
  owner: "Owner",
  activationDate: "Activation Date",
  expirationDate: "Expiration Date",
  maintenanceExpirationDate: "Maintenance Expiration Date",
  maintenanceExp: "Maintenance Exp.",
  deviceName: "Device",
  deviceUniqueId: "Unique ID",
  deviceDescription: "Description",
  installedProduct: "Installed Software",
  installedVersion: "Version",
  active: "Active",
  suspended: "Suspended",
  currentTrialStep: "Trial step",
  licenseHistory: "License history",
  duration: "Duration",
  noExpiration: "No expiration",
  backToLicenses: "Back to licenses",
  licenseNew: "New license",
  quantity: "Quantity",
  generateSeparateKeys: "Generate separate keys",
  licenseCreateError: "Error creating license",
  licenseCreateSuccess: "License created",
  generateExtensions: "Add Extensions",
  type: "Type",
  trialLicense: "Trial",
  fullLicense: "Full",

  rulesRequired: "Required field",
  rulesEmail: "Please insert a valid email",
  rulesNumber: "Please insert a valid number",
  rulesMatchPassword: "Password must match",

  signInTitle: "Sign in to your account",
  signInDescription: "Enter the credentials to sign in",
  signInEmail: "Email",
  insertCode: "Insert mfa code",
  signInPassword: "Password",
  signInForgot: "Forgot password?",
  signInCta: "Sign in",
  signInLoginError: "Incorrect username or password",
  signInFetchProvidersError: "Error fetching MFA plugins",
  signInGoogle: "Sign in with Google",
  signInMicrosoft: "Sign in with Microsoft",
  signInApple: "Sign in with Apple",
  forgotTitle: "Forgot password",
  forgotDescription:
    "Enter the email address associated with your account and we'll send you a link to reset your password",
  forgotBack: "Return to sign in",
  forgotSubmit: "Submit",
  forgotSuccess: "Recover message sent, please check yor email",
  forgotError: "Error in password recover, please retry",
  resetTitle: "Reset password",
  resetDescription: "Insert new password",
  resetPassSuccess: "Password set",
  resetPassError: "Password set error, please retry",
  resetCta: "Reset password",
  confirmMailSuccess: "Email confirmed",
  confirmMailError: "Mail confirmation error",
  confirmTitle: "Email confirmed",
  confirmDescription: "Insert new password",
  confirmPassCta: "Confirm password",
  logout: "logout",

  mfaChoseProvider: "Select MFA provider",
  mfaProviders: "Multifactor Authentication",
  mfaDisableProvider: "Provider disabled",
  mfaDisableProviderError: "Error disabling provider",
  mfaEnableProvider: "Provider enabled",
  mfaEnableProviderError: "Error enabling provider",
  mfaSelectProviderError: "Error selecting provider",
  mfaShowBackupCodes: "Backup codes",
  mfaBackupCodes: "Backup codes",
  mfaBackupCodesDescription:
    "This list of one-time-use codes will help you reset your MFA. If you’re locked out of your account because your phone is lost, damaged, or replaced, or you cant access your email, you can use a backup code to reset your MFA and get back into your account. Copy this codes and keep them in a safe place.",
  mfaEmailMessage: "A message has been sent, please check your email",
  mfaAuthenticatorMessage: "Please scan the QR code with an authenticator app",
  mfaInsertCode: "Insert code",
  disable: "Disable",
  enable: "Enable",

  products: "Products",
  product: "Product",
  backToProducts: "Back to products",
  isLicense: "License",
  licensePrefix: "License Prefix",
  licenseDuration: "License duration",
  licenseActivationRule: "License activation rule",
  islicenseUpgradeRight: "Upgradable",
  isLicenseTrial: "Trial License",
  firstnextmonth: "first day of the month",
  productType: "Product Type",
  productFeatures: "Product Features",
  environmentType: "Environment Type",
  discountType: "Discount Type",
  discountAmount: "Discount Amount",
  customPrice: "Custom Price",
  customPriceDiscountAmount: "Discount Amount",
  productItemDateFrom: "Valid from",
  productItemDateTo: "Valid until",
  currency: "Currency",
  licenseMaxVersion: "Max product version",
  licenseDurationYears: "Duration years",
  licenseDurationMonths: "Duration months",
  licenseDurationDays: "Duration days",
  tenants: "Tenants",
  licenseMaintenance: "License Maintenance",
  maintenanceDurationYears: "Maintenance years",
  maintenanceDurationMonths: "Maintenance months",
  maintenanceDurationDays: "Maintenance days",
  extendedProductId: "Extension",
  subscriptionId: "Subscription",
  licenseUpdateSuccess: "License updated",
  licenseUpdateError: "Error updating license",
  productEdit: "Edit Product",
  productCreate: "Create Product",
  productDeleteSuccess: "Product deleted",
  productDeleteError: "Error deleting product",
  productEditError: "Error editing product",
  productEditSuccess: "Product saved",
  productCreateError: "error creating product",
  productCreateSuccess: "Product created",
  productRemove: "Delete Product",
  productRemoveDescription: "Are you sure you want to delete the product?",

  order: "Order",
  orders: "Orders",
  vendor: "Vendor",
  buyer: "Buyer",
  backToOrders: "Back to orders",
  createdBy: "Created by",
  paid: "Paid",
  orderHistory: "Order History",
  source: "Source",
  updateDate: "Update Date",
  orderItems: "Order Items",
  newSellOut: "New sell out order",
  confirmSellOutTitle: "Confirm order creation",
  confirmSellOutDescription:
    "Do you confirm that you want to proceed? Prices cannot be changed later.",
  addProduct: "Select products to add",
  orderCreateError: "Errore creating order",
  total: "Total",
  newSellIn: "New sell in order",
  unknown: "Unknown",
  pending: "Pending",
  approved: "Approved",
  canceled: "Canceled",
  revoked: "Revoked",
  approve: "Approve",
  deny: "Deny",
  cancel: "Cancel",
  revoke: "Revoke",
  orderUpdateSuccess: "Order updated",
  orderUpdateError: "Error updating orders",
  orderRevokeError: "Can not revoke order. Insufficient stock or already used.",
  refCode: "Order reference",
  notes: "Notes",
  orderTotal: "Order total",
  filterCreationDate: "Filter by creation date",
  filterActivationDate: "Filter by activation date",
  filterExpirationDate: "Filter by expiration date",
  filterMaintenanceDate: "Filter by maintenance expiration date",
  filterParent: "Filter by parent account",
  downloadReport: "Download report",
  downloadReportError: "Download report error",
  reports: "Reports",

  clientRef: "Client reference",

  ordersFetchError: "Error fetching orders",
  productsFetchError: "Error fetching products",
  productsTypesFetchError: "Error fetching product types",
  productsTenantsFetchError: "Error fetching product tenants",
  productsTypes: "Product Types",
  productTypeDeleteSuccess: "Type deleted",
  productTypeDeleteError: "Error deleting type",
  productTypeEdit: "Edit Product Type",
  accountsFetchError: "Error fetching accounts",
  accountsGetError: "Error fetching account",
  accountsPutError: "Error updating account",
  accountsAssociatedListError: "Error fetching price list",
  usersFetchError: "Error fetching users",
  countriesFetchError: "Error fetching countries",
  currenciesFetchError: "Error fetching currencies",
  accountGroupsFetchError: "Error fetching account groups",
  rolesFetchError: "Error fetching roles",
  stocksFetchError: "Error fetching stocks",
  insufficientStockError: "Insufficient stock for the following products:",
  missing: "missing",
  pleasePurchase: "Please proceed with a Purchase Order first.",
  notAvailableProducts:
    "No available products in your stock. Please proceed with a Purchase Order (Sell in) first.",

  newUser: "New User",
  backToAccount: "Back to Account",
  role: "Role",
  newUserError: "Errore creating user",
  newUserSuccess: "User created",
  userRemove: "Delete User",
  userRemoveRemoveDescription: "Are you sure you want to delete the user?",
  userRemoveYourselfDescription:
    "Are you sure you want to delete your account? You won't be able to login anymore",
  userRemoved: "User removed",
  userRemoveError: "Error deleting user",

  certificatesFetchError: "Error fetching certifications",
  certificatesUpdateError: "Error updating certification",
  certificatesDeletionError: "Error deleting certification",
  certificateRemove: "Remove User Certification",
  certificateRemoveRemoveDescription:
    "Do you want to remove the certification from the user?",
  backToCertifications: "Certifications List",
  certificate: "Certificate: ",
  certificationCreationDate: "Certificate Creation Date",
  certificationExpirationDate: "Certificate Expiration Date",
  newUserCertification: "New User Certification",
  newAccountCertification: "New Account Certification",
  addNewUserCertification: "Certification Created",
  accountCertificate: "Account Certifications",
  accountCertificatesList: "Account Certificate List",
  certificateAccountRemove: "Remove Account Certificate",
  certificateAccountRemoveRemoveDescription:
    "Do you want to remove the certification from the account?",
  certificateName: "Certificate Name",
  certificates: "Certificates",
  renewCertificate: "Renew",
  downloadCertificate: "Download Certificate",
  createAccountCertification: "Certification Created",
  deleteAccountCertification: "Certification Deleted",
  deleteUserCertification: "Certification Deleted",
  updateUserCertificationNotes: "Certification Notes Updated",
  renewUserCertification: "Certification Renewed",
  duplicateUserCertification: "Certification already assigned to user",
  duplicateAccountCertification: "Certification already assigned to account",
  validateUserCertification: "Validate Certification",
  validationCode: "Certificate ID",
  validationPlaceholder: "e.g. ABC123",
  validateTitle: "Verify Certificate",
  validateDescription: "Enter a Certificate ID to verify",
  validCertificate: "Valid certificate",
  validationCodeNotFound: "Invalid certificate",
  validateError: "There was an error",
  validateCertificateGeneralError:
    "Error while validating certificate, please retry",
  expiredCertificate: "Expired certificate",
  validate: "Verify",
  validationPage: "validate certificate",
  userCertifications: "User Certifications",
  renewCertificateError: "Error while renewing certificate",
  guestCertificate: "Guest Certificate",
  valid: "Valid",

  user: "User",
  users: "Users",
  idCertificate: "Certificate ID",

  ODM: "ODM",
  OEM: "OEM",
  OPCO: "OPCO",
  DISTRIBUTOR: "Distributor",
  RESELLER_PLATINUM: "Reseller Platinum",
  RESELLER_GOLD: "Reseller Gold",
  RESELLER_SILVER: "Reseller Silver",
  BUYER: "Buyer",

  user_pending: "Pending",
  user_active: "Active",
  user_suspended: "Suspended",
  New: "New",
  Active: "Active",
  Pending: "Pending",
  Deleted: "Deleted",
  Approved: "Approved",
  Refused: "Refused",
  Duplicated: "Duplicated",

  "YYYY/MM/DD": "YYYY/MM/DD",
  AccountAdmin: "Administrator",
  Basic: "Basic user",
  SuperAdmin: "Super Admin",

  accountStatsError: "Error fetching stats",
  newSubscribers: "New subscribers",
  chosePeriod: "Chose a period",
  currentPeriod: "Current Period",
  previousPeriod: "Previous Period",
  includePrevious: "Include previous period",
  from: "from",
  to: "to",
  week: "week",
  includeChildren: "Include children accounts",
  onlyMyLicenses: "Show my licenses only",
  onlyMyOrders: "Show my orders only",
  onlyMyDeals: "Show my deals only",
  newLicenses: "Generated licenses",
  newDeals: "New deals",
  licenseKeyCopied: "License key copied to clipboard",
  orderReference: "Order reference",

  deals: "Deal registration",
  deal: "Deal",
  dealsFetchError: "Error fetching deals",
  dealsHistoryFetchError: "Error fetching deals history",
  dealUpdateSuccess: "Deal updated",
  dealUpdateError: "Error updating deal",
  estimatedCloseDate: "Estimated Close Date",
  eBidStatus: "Bid status",
  protectionStatus: "Protection status",
  dealStatus: "Deal status",
  backToDeals: "Back to deals",
  prospect: "Prospect",
  companyName: "Company name",
  prospectName: "Prospect name",
  webSite: "Website",
  dealItems: "Deal items",
  expired: "Expired",
  startDate: "Start date",
  closureChance: "Closing Probability",
  phone: "Phone",
  Trial: "Trial",
  Demo: "Demo",
  Negotiation: "Negotiation",
  Won: "Closed with success",
  Lost: "Not closed",
  dealHistory: "Deal History",
  eBid: "EBid",
  eBidValue: "Bid proposal",
  eBidApprovedValue: "Approved bid",
  expiration: "Expiration",
  markAsDuplicated: "Mark as duplicated",
  update: "Update",
  newDeal: "New deal",
  dealProtection: "Deal Protection",
  dealDescription: "Deal description",
  updateOrigin: "Update origin",
  Unknown: "Unknown",
  Creation: "Creation",
  UpdatedByCreator: "Updated by creator",
  UpdatedByApprover: "Updated by approver",
  System: "System",

  stocks: "Stock",
  noAccountError: "No account selected",
  airServerRequired: "AirServer Required",
  onPremEnvFetchError: "Error fetching On Premise Environments",
  onPremEnvDeleteSuccess: "On Premise Enveh esironment deleted",
  onPremEnvDeleteError: "Error deleting On Premise Environment",
  onPremEnvSaveSuccess: "On Premise Environment saved",
  onPremEnvSaveError: "Error saving On Premise Environment",
  onPremEnv: "On Premise Environment",
  customerName: "On Premise Environment Name",
  customerEnvironmentId: "On Premise Environment Id",
  onPremEnvRemove: "Delete Environment",
  onPremEnvRemoveDescription: "Delete On Premise environment Environment",
  backToOnPremEnv: "Back to Environments",
  exportLicenseOnPrem: "Export license to On Premise Environment",
  hasBeenExported: "Exported",
  licenseExportError: "Error exporting License",
  productFeatureDeleteSuccess: "Feature deleted",
  productFeatureDeleteError: "Error deleting feature",
  productFeatureSaveSuccess: "Feature saved",
  productFeatureSaveError: "Error saving feature",
  created: "Created",
  activated: "Activated",
  licenseExp: "License exp.",
  inactive: "Inactive",
  noDate: "(no date)",
  yes: "Yes",
  no: "No",
  perpetual: "Perpetual",
  extendedSku: "Extended SKU",
  notExpired: "Valid",
  totalCreated: "Created",
  totalActive: "Active",
  totalExpired: "Expired",
  totalMaintenanceExpired: "Maintenance Expired",
  totalNearMaintenanceExpiration: "Soon expiring",
  totalSellIn: "Total Sell-In",
  totalSellOut: "Total Sell-Out",
  totalSellInPrice: "Sell-In total value",
  totalSellOutPrice: "Sell-Out total value",
  intervalWeek: "Last week",
  intervalThisMonth: "This month",
  intervalLastMonth: "Last month",
  intervalThisQuarter: "This quarter",
  intervalLastQuarter: "Last quarter",
  intervalThisYear: "This year",
  intervalLastYear: "Last year",
  intervalAllTime: "All time",
  intervalCustom: "Custom range",
  nodata: "No data to display.",
  noProduct: "No products",
  totalSeats: "Total seats",
  ofWhich: "of which",
  used: "used",
  files: "Files",
  filesFetchError: "Error fetching files",
  size: "size",
  uploadBy: "Uploaded by",
  uploadDate: "Upload date",
  fileRemoveDescription: "Do you want to delete file?",
  fileRemove: "Delete file",
  fileRemoveSuccess: "File deleted",
  fileRemoveError: "Error deleting file",
  accountFiles: "Account Files",
  fileUploadSuccess: "File uploaded",
  fileUploadError: "Error uploading file",
  fileEditSuccess: "File saved",
  fileEditError: "Error editing file",
  fileDownloadError: "Error downloading file",
  download: "Download",
  filterUploadDate: "Filter by upload date",
  fileSizeError: "Invalid file size",
};
